import "vite/modulepreload-polyfill";
import jQuery from "jquery";
import PubSub from "pubsub-js";
import "../../../../vendor/willisbondlimited/website-core/client/js/pace";
import "../../../../vendor/willisbondlimited/website-core/client/js/index";
import "../../../../vendor/willisbondlimited/website-core/client/js/components/labels";
import { throttle } from "../../../../vendor/willisbondlimited/website-core/client/js/util/throttle";
import Lenis from "@studio-freight/lenis";
import Swiper from "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/style.scss";

import { Navigation } from "swiper/modules";

document.addEventListener("DOMContentLoaded", function() {
  const $ = jQuery;

  AOS.init({
    delay: 0,
    duration: 600,
    easing: "ease-out",
    once: false,
    offset: 0,
    debounceDelay: 20
  });

  const lenis = new Lenis({
    lerp: 0.1
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  function isDesktop() {
    return window.innerWidth >= 920;
  }

  // clicking header-menu.current should close the menu

  const swiper2 = new Swiper(".swiper", {
    modules: [Navigation],
    loop: true,
    centeredSlides: true,
    slidesPerView: 1.2,
    spaceBetween: 12,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    on: {
      init: function(sw) {
        var offer = document.querySelector(".swiper-number");
        if (offer) {
          offer.innerHTML = sw.realIndex + 1 + "/" + sw.slides.length;
        }
      },
      slideChange: function(sw) {
        var offer = document.querySelector(".swiper-number");

        if (offer) {
          offer.innerHTML = sw.realIndex + 1 + "/" + sw.slides.length;
        }
      }
    },
    breakpoints: {
      920: {
        slidesPerView: 1,
        centeredSlides: false
      }
    }
  });

  window.DISABLE_AJAX_NAVIGATION = true;

  if (isDesktop()) {
    $(".landing-video--mobile").remove();
    $(".landing-video--desktop").css("opacity", 1);
  } else {
    $(".landing-video--desktop").remove();

    $(".landing-video--mobile").css("opacity", 1);
  }

  if ($(".skip-pre-roll").length > 0 || window.location.hash) {
    $(".pre-roll").addClass("out");
  }

  const logoAnimation = document.querySelector(".logo:has(video)");

  // if logo animation, play the video on hover. The video is muted and plays on hover
  if (logoAnimation) {
    logoAnimation.addEventListener("mouseenter", function() {
      const video = logoAnimation.querySelector("video");

      if (video) {
        // set to first frame
        video.currentTime = 0;

        // set to loop
        video.loop = true;
        video.play();
      }
    });

    // on mouse out, set it to not loop, finish then stop
    logoAnimation.addEventListener("mouseleave", function() {
      const video = logoAnimation.querySelector("video");

      if (video) {
        video.loop = false;

        video.addEventListener("ended", function() {
          video.pause();
        });
      }
    });
  }

  PubSub.subscribe("ON_PACE_DONE", function() {
    $(".header-menu .current").on("click", function() {
      // scroll to the anchor in the href
      const href = $(this).attr("href");

      if (href) {
        // strip anything before the # off href
        const hash = href.split("#")[1];

        const target = document.querySelector(`#${hash.toLowerCase()}`);
        if (target) {
          target.scrollIntoView();
          // update the url
          history.pushState({}, "", href);
          setTimeout(function() {
            $("html").removeClass("menu-open");
          }, 300);
        }
      }
    });

    if ($(".skip-pre-roll").length > 0) {
      $(".pre-roll").addClass("out");
    } else {
      // fade .pre-roll--skip in after 3 seconds
      setTimeout(function() {
        $(".pre-roll--skip").addClass("in");
      }, 3000);

      var isOut = false;

      // on click of .pre-roll--skip, animate the pre-roll to a height of 0
      $(".pre-roll--skip").on("click", function() {
        $(".pre-roll").addClass("out");

        isOut = true;

        $(".dst-sidebar--hidden").removeClass("dst-sidebar--hidden");
      });

      // if there is a .pre-roll--skip, when the user scrolls make sure to add the
      // out class to the .pre-roll
      if ($(".pre-roll--skip").length) {
        $(window).on("scroll", function() {
          if (isOut) {
            return;
          }
          $(".pre-roll").addClass("out");
          isOut = true;
        });
      } else {
        isOut = true;

        $(".pre-roll").addClass("out");
      }
    }
  });

  const currentLinks = document.querySelectorAll("a.current[href]");

  if (currentLinks.length) {
    currentLinks.forEach(function(link) {
      link.addEventListener("click", function(e) {
        e.preventDefault();

        const href = link.getAttribute("href");

        if (href) {
          // strip anything before the # off href
          const hash = href.split("#")[1];

          const target = document.querySelector(`#${hash.toLowerCase()}`);

          if (target) {
            // close the menu
            $("html").removeClass("menu-open");

            setTimeout(function() {
              target.scrollIntoView({
                behavior: "smooth"
              });

              console.log("target", target);
            }, 300);
          }
        }
      });
    });
  }

  // header is fixed so body needs top padding of .header height
  const headerHeight = $(".header").outerHeight();
  $("body").css("padding-top", headerHeight);

  // update height on resize
  $(window).on("resize", function() {
    const headerHeight = $(".header").outerHeight();
    $("body").css("padding-top", headerHeight);

    // css variable for --header-height
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  });

  // for each .form-step add a 'Step 1' to a h2 within it
  $(".form-step").each(function(i) {
    const step = i + 1;
    $(this).prepend(`<h2 class="caps">Step 0${step}</h2>`);

    // add an anchor to this step
    $(this).attr("id", `step-0${step}`);
  });

  $(".video-trigger").each(function(elem) {
    const videoElement = $(elem).parents("video");

    const iframe = videoElement.find("iframe");

    if (iframe.length > 0) {
      const player = new Vimeo.Player(iframe.get(0));

      player.ready().then(function() {
        $(elem).on("click", function(e) {
          e.preventDefault();

          player.setVolume(1);
          player.play();

          videoElement?.classList.add("playing");
        });
      });
    }
  });

  // if we're on a body__SurveyPage add a 'page-scroll' for the '.userform' element
  // with each of the .form-step elements as a list item
  if ($("body").hasClass("body__SurveyPage")) {
    $(".form-content__right ").prepend(
      $(
        "<div class='page-scroll'><ul></ul><div class='page-scroll--position'></div></div>"
      )
    );

    let step = 0;

    $(".form-step").each(function(i) {
      step = i + 1;
      $(".page-scroll ul").append(
        `<li class="page-scroll__item"><a href="#step-0${step}"><span>Step 0${step}</span></a></li>`
      );
    });

    $("body").on("click", ".page-scroll__item a", function(e) {
      e.preventDefault();

      const href = $(this).attr("href");
      const offset = $(href).offset().top;

      $("html,body").animate(
        {
          scrollTop: offset - 100
        },
        500
      );
    });

    // on click on a label.mappicker__place set the active class on every other .mappicker__place with the same
    // data-value
    $("body").on("click", "label.mappicker__place", function() {
      const value = $(this).attr("data-value");

      $(".mappicker__place").each(function() {
        if ($(this).attr("data-value") === value) {
          $(this).addClass("active");
        } else {
          $(this).removeClass("active");
        }
      });

      // update the select in .mappicker with this selected value
      $(".mappicker select").val(value);
    });

    $("body").on("click", "a.mappicker__place", function(e) {
      e.preventDefault();

      if ($(this).hasClass("active")) {
        return;
      }

      $(".mappicker__place").removeClass("active");
      $(this).addClass("active");

      // update the select in .mappicker with this selected value
      const value = $(this).attr("data-value");
      $(".mappicker select").val(value);

      // highlight the label.mappicker__place with the same value
      $("label.mappicker__place").each(function(i, label) {
        if ($(label).attr("data-value") === value) {
          $(label).addClass("active");
        } else {
          $(label).removeClass("active");
        }
      });
    });

    // add a handler to scroll which sets the height of page-scroll--position as the user scrolls
    const highlightBar = function() {
      const scroll = window.scrollY;

      let block = 0;

      $(".form-step").each(function(i) {
        const offset = $(this).offset().top;

        if (scroll >= offset - window.innerHeight / 2) {
          block = i;
        }
      });

      let percentage = block / ($(".form-step").length - 1);

      $(".page-scroll--position").css("height", `${percentage * 100}%`);

      // highlight any li any page-scroll__item which is less than or equal to the block
      $(".page-scroll__item").each(function(i) {
        if (i <= block) {
          $(this).addClass("active");
        } else {
          $(this).removeClass("active");
        }
      });
    };

    $(window).on("scroll", function() {
      highlightBar();
    });

    highlightBar();
  }

  // video should scroll to full width and height in the container
  const video = document.querySelectorAll(".video");

  if (video.length) {
    video.forEach(function(v) {
      let holder = v.querySelector(".video-holder");
      let targetScale = 0;

      const updateVideoSize = function() {
        if (!isDesktop()) {
          holder.style.aspectRatio = "16 / 9";
          v.style.height = "auto";
          return;
        }

        var targetContainerWidth =
          document.querySelector(".container")?.clientWidth || 0;
        targetScale = targetContainerWidth / window.innerWidth;
        const containerDifference = window.innerWidth - targetContainerWidth;
        const headerHeight = $(".header").outerHeight();

        v.style.height = `calc(${window.innerHeight +
          containerDifference}px - var(--header-height))`;

        // set video holder to the aspect ratio of the screen
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const calculatedHeight = screenHeight - headerHeight;
        holder.style.aspectRatio = `${screenWidth} / ${calculatedHeight}`;
      };

      // by default start at scale 50% and
      const onVideoScroll = function() {
        if (!isDesktop()) {
          holder.style.transform = `translateY(0px) scale(1)`;

          return;
        }

        const scrolled = window.scrollY;
        const windowHeight = window.innerHeight;
        const topOfVideo = v.offsetTop;
        const headerHeight = $(".header").outerHeight();
        const bottomOfVideo = topOfVideo + v.offsetHeight;
        const bottomOfViewport = scrolled + windowHeight;

        // not yet in view
        if (bottomOfViewport < bottomOfVideo) {
          holder.style.transform = `translateY(0px) scale(${targetScale})`;
          return;
        }

        // start the scale at 50% of the video in the view
        const distanceScrolledInVideo =
          bottomOfViewport - (topOfVideo + windowHeight);

        const maxScrollDistance =
          bottomOfVideo - topOfVideo - windowHeight + 100;
        // somewhere in the middle
        const percentageScrolled = Math.min(
          distanceScrolledInVideo / maxScrollDistance,
          1
        );

        const diff = (1 - targetScale) * percentageScrolled;
        const scale = targetScale + diff;
        const ty = headerHeight * percentageScrolled;
        holder.style.transform = `scale(${scale}) translateY(${ty}px)`;
      };

      // debounce onVideoScroll
      const throttledOnVideoScroll = throttle(onVideoScroll, 10);

      $(window).on("scroll", throttledOnVideoScroll);

      updateVideoSize();
      onVideoScroll();

      $(window).on("resize", function() {
        updateVideoSize();
        onVideoScroll();
      });
    });
  }
});
